"use client";

import { customTwMerge } from "../../../utils/customTwMerge";
import "../../../styles/index.css";
import { designSystemColors } from "../../../styles/colors";
import { SeparatorProps } from "./Separator.type";
import { SEPARATOR_TYPE_STYLE_MAP } from "./Separator.style";

export function Separator({
  type,
  separatorColor = designSystemColors["separator-default"],
  className,
  ...restProps
}: SeparatorProps) {
  return (
    <div
      className={customTwMerge(SEPARATOR_TYPE_STYLE_MAP[type], className)}
      {...restProps}
    >
      <div
        className="h-full w-full"
        style={{ backgroundColor: separatorColor }}
      />
    </div>
  );
}
